import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../data.service';
import { ActivatedRoute } from '@angular/router';
import {MatSidenav} from '@angular/material/sidenav';
import { Router } from '@angular/router';

@Component({
  selector: 'app-case',
  templateUrl: './case.component.html',
  styleUrls: ['./case.component.css']
})
export class CaseComponent implements OnInit {
  @ViewChild('sidenav') sidenav: MatSidenav;
  reason = '';
  showOpenButton = false;
  showCloseButton = true;
  case;
  constructor(private route: ActivatedRoute, private dataService: DataService, private router: Router) { }

  close(reason: string) {
    this.reason = reason;
    this.sidenav.close();
    this.showCloseButton = false;
    this.showOpenButton = true;
  }

  open(){
    this.sidenav.open();
    this.showCloseButton = true;
    this.showOpenButton = false;
  }

  ngOnInit() {
  	var caseId = this.route.snapshot.params['id'];
  	this.dataService.getCase(caseId)
      .subscribe(res => {
      		// console.log(res);
          this.case = res.case;
          this.router.navigate(['', { outlets: { case_router_outlet: 'manage' } }]);
      	});
  }

}
