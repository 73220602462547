import { Component, OnInit, OnChanges } from "@angular/core";
import { DataService } from "../data.service";
import { AuthenticationService } from "../authentication.service";
import { ActivatedRoute } from "@angular/router";
import { MatSnackBar } from "@angular/material";
import { ViewChild } from "@angular/core";
import { AngularFileUploaderComponent } from "angular-file-uploader";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { MatChipInputEvent } from "@angular/material";

export interface Tag {
  name: string;
}

@Component({
  selector: "app-video-create",
  templateUrl: "./video-create.component.html",
  styleUrls: ["./video-create.component.css"]
})
export class VideoCreateComponent implements OnInit {
  videoTitle: string = "";

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  tags: Tag[] = [];

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our tag
    if ((value || "").trim()) {
      this.tags.push({ name: value.trim() });
    }

    // Reset the input value
    if (input) {
      input.value = "";
    }
  }

  remove(tag: Tag): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  @ViewChild("fileUpload1")
  private fileUpload1: AngularFileUploaderComponent;

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private dataService: DataService,
    private authService: AuthenticationService,
    private snackBar: MatSnackBar,
    private _formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ["", Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ["", Validators.required]
    });

    this.afuConfig = {
      multiple: false,
      formatsAllowed: ".mp4",
      maxSize: "1999",
      uploadAPI: {
        url: `${this.dataService.API}/video/upload`,
        headers: {
          Authorization: `Bearer ${this.authService.getToken()}`
        }
      },
      theme: "dragNDrop",
      hideProgressBar: false,
      hideResetBtn: true,
      hideSelectBtn: false
    };
  }

  afuConfig = {};

  DocUpload(apiResponseEvent) {
    console.log(this.videoTitle);
    console.log(this.tags);
    var jsonRes = JSON.parse(apiResponseEvent.responseText);
    console.log(jsonRes.videoUrl);

    // now create the entry
    let videoUrl = jsonRes.videoUrl;
    if (videoUrl) {
      //success
      // convert Tag array to String array
      var tags = [];
      this.tags.forEach(function myFunction(value, index, array) {
        tags.push(value.name);
      });
      this.dataService
        .createVideo(videoUrl, this.videoTitle, tags)
        .subscribe(res => {
          // this.fileUpload1.resetFileUpload();
        });
    }

    // this.fileUpload1.resetFileUpload();
    // if (jsonRes.result == false) {
    //   this.snackBar.open(jsonRes["messages"].toString(), "Undo", {});
    // } else {
    // }
  }
}
