import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ValidatorService } from 'angular4-material-table';

@Injectable({
  providedIn: 'root'
})
export class PlanPhaseItemValidatorService implements ValidatorService{
  getRowValidator(): FormGroup {
    return new FormGroup({
      'planphase': new FormControl(null),
      'budget': new FormControl(null),
      'work': new FormControl(null)      
      });
  }
  constructor() { }
}
