import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule, Routes } from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatButtonModule, MatCheckboxModule, MatFormFieldModule, MatOptionModule, MatSelectModule, MatInputModule, MatCardModule, MatTableModule, MatIconModule, MatSortModule, MatMenuModule, MatSidenavModule, MatListModule, MatToolbarModule, MatSnackBarModule, MatProgressSpinnerModule, MatTabsModule, MatDatepickerModule, MatNativeDateModule, MatExpansionModule, MatStepperModule, MatChipsModule} from '@angular/material';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { RegisterComponent } from './register/register.component';
import { HomeComponent } from './home/home.component';
import { AuthenticationService } from './authentication.service';
import { AuthGuardService } from './auth-guard.service';
import { VideosComponent } from './videos/videos.component';
import { DataService} from './data.service';
import { CaseComponent } from './case/case.component';
import { TeamComponent } from './case/team/team.component';
import { PlanComponent } from './case/plan/plan.component';
import { WorkComponent } from './case/work/work.component';
import { ManageComponent } from './case/manage/manage.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AngularFileUploaderModule } from "angular-file-uploader";
import { VideoCreateComponent } from './video-create/video-create.component';

const appRoutes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'about', component: AboutComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'videos', component: VideosComponent, canActivate: [AuthGuardService]},
  { path: 'video/create', component: VideoCreateComponent, canActivate: [AuthGuardService]},
  { 
    path: 'case/:id',
    children: [
      { path: '', component: ManageComponent,   outlet: 'case_router_outlet'},
      { path: 'team', component: TeamComponent,   outlet: 'case_router_outlet'},
      { path: 'plan', component: PlanComponent,   outlet: 'case_router_outlet'},
      { path: 'work', component: WorkComponent,   outlet: 'case_router_outlet'},
      { path: 'manage', component: ManageComponent,   outlet: 'case_router_outlet'},
    ],
    canActivate: [AuthGuardService]
  },
  { path: '**', component: HomeComponent }
];


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AboutComponent,
    ContactComponent,
    RegisterComponent,
    HomeComponent,
    VideosComponent,
    CaseComponent,
    TeamComponent,
    PlanComponent,
    WorkComponent,
    ManageComponent,
    VideoCreateComponent,
  ],
  imports: [
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: true } // <-- debugging purposes only
    ),
    NgbModule.forRoot(),
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    MatInputModule,
    MatCardModule,
    MatTableModule,
    MatIconModule,
    MatSortModule,
    MatMenuModule,
    MatSidenavModule,
    MatListModule,
    MatToolbarModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatExpansionModule,
    CurrencyMaskModule,
    NgxMatSelectSearchModule,
    AngularFileUploaderModule,
    MatStepperModule,
    MatChipsModule
  ],
  providers: [
    AuthenticationService,
    AuthGuardService,
    DataService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }