import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { TableDataSource, TableElement, ValidatorService } from 'angular4-material-table';
import { TimeKeeper } from './timekeeper';
import { TimekeeperValidatorService } from './timekeeper-validator.service';
import { DataService } from '../../data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { MatSelect } from '@angular/material';

interface Role {
  _id: string;
  name: string;
}

@Component({
  selector: 'app-team',
  providers: [
  {provide: ValidatorService, useClass: TimekeeperValidatorService }
  ],
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.css']
})
export class TeamComponent implements OnInit, OnDestroy {

  resultAvailable: boolean = false;

  @ViewChild('singleSelect') singleSelect: MatSelect;

  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();

  constructor(private timekeeperValidator: ValidatorService, private dataService: DataService, private activatedRoute: ActivatedRoute, private router: Router) {}

  displayedColumns = ['role','name','lawfirm','timekeeper_id','justification','rate','status','comments', 'actionsColumn'];

  @Input() timekeeperList: Array<TimeKeeper> = [];

  roles: Array<Role>;
  statuses: Array<string>;
  lawfirms: Array<Object>;

  @Output() timeKeeperListChange = new EventEmitter<TimeKeeper[]>();

  dataSource: TableDataSource<TimeKeeper>;

  /** control for the MatSelect filter keyword */
  roleFilterCtrl: FormControl = new FormControl();

  /** list of timekeeper roles filtered by search keyword */
  public filteredRoles: ReplaySubject<Role[]> = new ReplaySubject<Role[]>(1);

  ngOnInit() {
    // get timekeeper roles
    this.dataService.getTimeKeeperRoles()
    .subscribe(res => {
      this.roles = res;
      this.filteredRoles.next(this.roles.slice());
          // console.log(res);
        });

    // get law firms
    this.dataService.getLawFirms()
    .subscribe(res => {
      this.lawfirms = res;
        // console.log(res);
      });

    // get timekeeper statuses
    this.dataService.getTimeKeeperStatuses()
    .subscribe(res => {
      this.statuses = res;
      // console.log(res);
    });

    this.roleFilterCtrl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterRoles();
    });

    let caseId = this.activatedRoute.parent.snapshot.params["id"];
    // console.log('caseId is '+caseId);
    this.dataService.getTimeKeepersByCaseId(caseId, null)
      .subscribe(res => {
        setTimeout(() => 
        {
          this.resultAvailable = true;
          this.timekeeperList = res;
          // console.log(res);

          this.dataSource = new TableDataSource<any>(this.timekeeperList, TimeKeeper, this.timekeeperValidator);
          this.dataSource.datasourceSubject.subscribe(timekeeperList => {
            // console.log(timekeeperList);
            this.timeKeeperListChange.emit(timekeeperList)
          });
        },1000);
      });

    // this.timeKeeperListChange.subscribe(res => {
      // console.log(res);
    // });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  createNewTimeKeeper() {
    this.dataSource.createNew();
  }

  createOrEditTimeKeeper(row: TableElement<TimeKeeper>){
    let timeKeeperId = row.currentData._id;    
    if(timeKeeperId == null){
      console.log("create");
      // create
      if(row.validator.valid){
        // use API to create this time keeper in backend first
        let caseId = this.activatedRoute.parent.snapshot.params["id"];
        this.dataService.createTimeKeeper(caseId, row.currentData)
        .subscribe(res => {
          row.currentData._id = res;
          row.confirmEditCreate();
        });
      }
    } else {
      console.log("edit");
      // edit
      if(row.validator.valid){
        // use API to edit this time keeper in backend
        // console.log(row.currentData);
        // use API to create this time keeper in backend first
        let caseId = this.activatedRoute.parent.snapshot.params["id"];
        this.dataService.updateTimeKeeper(caseId, row.currentData)
        .subscribe(res => {
          row.currentData._id = res;
          row.confirmEditCreate();
        });
      }
    }
    // refresh the entire list?
  }

  private filterRoles() {
    if (!this.roles) {
      return;
    }
    // get the search keyword
    let search = this.roleFilterCtrl.value;
    if (!search) {
      this.filteredRoles.next(this.roles.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the roles
    this.filteredRoles.next(
      this.roles.filter(role => role.name.toLowerCase().indexOf(search) > -1)
      );
  }
}