export class TimeKeeper {
  _id: string;
  timekeeper_role_id: string;
  name: string;
  law_firm_id: string;
  external_id: string;
  justification: string;
  rate: number;
  status: string = "Proposed";
  comments: string;
}