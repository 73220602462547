import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { TableDataSource, TableElement, ValidatorService } from 'angular4-material-table';
import { PlanItem } from './plan-item';
import { PlanItemValidatorService } from './plan-item-validator.service';
import { DataService } from '../../data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-plan',
  providers: [
  {provide: ValidatorService, useClass: PlanItemValidatorService }
  ],
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.css']
})
export class PlanComponent implements OnInit {

  displayedColumns = ['task_code', 'description', 'timekeeper','hours','fee','expense_code','amount','comments','actionsColumn'];
  resultAvailable: boolean = false;

  @Input() planItemList = [];

  taskCodes: Array<Object>;
  timeKeepers: Array<Object>;
  expenseCodes: Array<Object>;

  @Output() planItemListChange = new EventEmitter<PlanItem[]>();

  dataSource: TableDataSource<PlanItem>;

  constructor(private planItemValidator: ValidatorService, private dataService: DataService, private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    // get law firms
    this.dataService.getTaskCodes()
    .subscribe(res => {
      this.taskCodes = res;
        // console.log(res);
      });

    this.dataService.getExpenseCodes()
    .subscribe(res => {
      this.expenseCodes = res;
        // console.log(res);
      });

    let caseId = this.activatedRoute.parent.snapshot.params["id"];
    this.dataService.getTimeKeepersByCaseId(caseId, 'Approved')
    .subscribe(res => {
      this.timeKeepers = res;
      // console.log(res);
    });

    this.dataService.getPlanByCaseId(caseId)
    .subscribe(res => {
      setTimeout(() => 
      {
        this.resultAvailable = true;
        this.planItemList = res;
        this.dataSource = new TableDataSource<any>(this.planItemList, PlanItem, this.planItemValidator);
        this.dataSource.datasourceSubject.subscribe(planItemList => {
          // console.log(planItemList);
          this.planItemListChange.emit(planItemList);
        });
      },
      1000);
    });
  }

  createNewPlanItem() {
    this.dataSource.createNew();
  }

  createOrEditPlanItem(row: TableElement<PlanItem>){
    let planItemId = row.currentData._id;
    if(planItemId == null){
      // create
      if(row.validator.valid){
        // console.log("create");
        // use API to create this plan item in backend first
        let caseId = this.activatedRoute.parent.snapshot.params["id"];
        this.dataService.createPlanItem(caseId, row.currentData)
        .subscribe(res => {
          row.currentData._id = res;
          row.confirmEditCreate();
        });
      }
    } else {
      // edit
      if(row.validator.valid){
        console.log("edit");  
        // use API to edit this Plan Item in backend
        // console.log(row.currentData);
        // use API to create this time keeper in backend first
        let caseId = this.activatedRoute.parent.snapshot.params["id"];
        this.dataService.updatePlanItem(caseId, row.currentData)
        .subscribe(res => {
          row.currentData._id = res;
          row.confirmEditCreate();
        });
      }
    }
    // refresh the entire list?
  }

  cancelOrDelete(row: TableElement<PlanItem>){

    if(row.editing){
      // cancel
      console.log('cancel');
      row.cancelOrDelete();
    } else {
      // delete
      console.log("delete");
      let planItemId = row.currentData._id;
      let caseId = this.activatedRoute.parent.snapshot.params["id"];
      this.dataService.deletePlanItem(caseId, planItemId)
      .subscribe(res => {
        row.currentData._id = res._id;
        row.cancelOrDelete();
      });
    }
    // refresh the entire list?
  }

  calculateFee(row: TableElement<PlanItem>){
    for (let timeKeeper of this.timeKeepers) {
      if(timeKeeper["_id"] == row.currentData.timekeeper_id){
        row.currentData.fee = timeKeeper["rate"] * row.currentData.hours;
        return;
      }
    }
  }

}