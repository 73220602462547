import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ValidatorService } from 'angular4-material-table';

@Injectable({
  providedIn: 'root'
})
export class PlanItemValidatorService implements ValidatorService{
  getRowValidator(): FormGroup {
    return new FormGroup({
      'task_code': new FormControl(null, Validators.required),
      'description': new FormControl(null, Validators.required),
      'timekeeper': new FormControl(null, Validators.required),
      'hours': new FormControl(null, Validators.required),
      'fee': new FormControl(null, Validators.required),
      'expense_code': new FormControl(null),
      'amount': new FormControl(null),
      'comments': new FormControl(null),
      });
  }
  constructor() { }
}
