import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { map } from 'rxjs/operators';
import { environment } from './../environments/environment';
import { TimeKeeper } from './case/team/timekeeper';
import { PlanItem } from './case/plan/plan-item';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  API = environment.apiUrl;

  constructor(private http: HttpClient, private authService: AuthenticationService) { }

  getCases() {
    let request = this.http.get(`${this.API}/cases`, { headers: { Authorization: `Bearer ${this.authService.getToken()}` } }).pipe(
      map((data: any) => {
        return data;
      })
    );
    return request;
  }

  getCase(caseId: string) {
    let request = this.http.get(`${this.API}/case/${caseId}`, { headers: { Authorization: `Bearer ${this.authService.getToken()}` } }).pipe(
      map((data: any) => {
        return data;
      })
    );
    return request;
  }

  getTimeKeepersByCaseId(caseId: string, status: string) {
    var apiUrl = `${this.API}/case/${caseId}/timekeepers`;
    if (status)
      apiUrl += `?status=${status}`;

    let request = this.http.get(apiUrl, { headers: { Authorization: `Bearer ${this.authService.getToken()}` } }).pipe(
      map((data: any) => {
        return data;
      })
    );
    return request;
  }

  getPlanByCaseId(caseId: string) {
    let request = this.http.get(`${this.API}/case/${caseId}/plan`, { headers: { Authorization: `Bearer ${this.authService.getToken()}` } }).pipe(
      map((data: any) => {
        return data;
      })
    );
    return request;
  }

  createTimeKeeper(caseId: string, timekeeper: TimeKeeper) {
    let request = this.http.post(`${this.API}/case/${caseId}/timekeepers`, timekeeper, { headers: { Authorization: `Bearer ${this.authService.getToken()}` } }).pipe(
      map((data: any) => {
        // console.log(data);
        return data._id;
      })
    );
    return request;
  }

  createPlanItem(caseId: string, planItem: PlanItem) {
    let request = this.http.post(`${this.API}/case/${caseId}/plan`, planItem, { headers: { Authorization: `Bearer ${this.authService.getToken()}` } }).pipe(
      map((data: any) => {
        // console.log(data);
        return data._id;
      })
    );
    return request;
  }

  createVideo(url: string, title: string, tags: string[]) {
    let request = this.http.post(`${this.API}/videos`, {"url": url, "title": title, "tags": tags}, { headers: { Authorization: `Bearer ${this.authService.getToken()}` } }).pipe(
      map((data: any) => {
        // console.log(data);
        return data._id;
      })
    );
    return request;
  }

  getVideos() {
    let request = this.http.get(`${this.API}/videos`, { headers: { Authorization: `Bearer ${this.authService.getToken()}` } }).pipe(
      map((data: any) => {
        return data;
      })
    );
    return request;
  }

  updateTimeKeeper(caseId: string, timeKeeper: TimeKeeper) {
    let request = this.http.post(`${this.API}/case/${caseId}/timekeepers/${timeKeeper._id}`, timeKeeper, { headers: { Authorization: `Bearer ${this.authService.getToken()}` } }).pipe(
      map((data: any) => {
        // console.log(data);
        return data._id;
      })
    );
    return request;
  }

  updatePlanItem(caseId: string, planItem: PlanItem) {
    let request = this.http.post(`${this.API}/case/${caseId}/plan/${planItem._id}`, planItem, { headers: { Authorization: `Bearer ${this.authService.getToken()}` } }).pipe(
      map((data: any) => {
        // console.log(data);
        return data._id;
      })
    );
    return request;
  }

  deletePlanItem(caseId: string, planItemId: string) {
    let request = this.http.delete(`${this.API}/case/${caseId}/plan/${planItemId}`, { headers: { Authorization: `Bearer ${this.authService.getToken()}` } }).pipe(
      map((data: any) => {
        console.log(data);
        return data;
      })
    );
    return request;
  }

  getTimeKeeperRoles() {
    let request = this.http.get(`${this.API}/timekeeperroles`, { headers: { Authorization: `Bearer ${this.authService.getToken()}` } }).pipe(
      map((data: any) => {
        // console.log(data);  
        return data;
      })
    );
    return request;
  }

  getLawFirms() {
    let request = this.http.get(`${this.API}/lawfirms`, { headers: { Authorization: `Bearer ${this.authService.getToken()}` } }).pipe(
      map((data: any) => {
        // console.log(data);  
        return data;
      })
    );
    return request;
  }

  getBusinessUnits() {
    let request = this.http.get(`${this.API}/cases/businessunits`, { headers: { Authorization: `Bearer ${this.authService.getToken()}` } }).pipe(
      map((data: any) => {
        // console.log(data);  
        return data;
      })
    );
    return request;
  }

  getApproaches() {
    let request = this.http.get(`${this.API}/cases/approaches`, { headers: { Authorization: `Bearer ${this.authService.getToken()}` } }).pipe(
      map((data: any) => {
        // console.log(data);  
        return data;
      })
    );
    return request;
  }

  getEndGames() {
    let request = this.http.get(`${this.API}/cases/endgames`, { headers: { Authorization: `Bearer ${this.authService.getToken()}` } }).pipe(
      map((data: any) => {
        // console.log(data);  
        return data;
      })
    );
    return request;
  }

  getTimeKeeperStatuses() {
    let request = this.http.get(`${this.API}/timekeeper/statuses`, { headers: { Authorization: `Bearer ${this.authService.getToken()}` } }).pipe(
      map((data: any) => {
        // console.log(data);  
        return data;
      })
    );
    return request;
  }

  getTaskCodes() {
    let request = this.http.get(`${this.API}/taskcodes`, { headers: { Authorization: `Bearer ${this.authService.getToken()}` } }).pipe(
      map((data: any) => {
        // console.log(data);  
        return data;
      })
    );
    return request;
  }

  getExpenseCodes() {
    let request = this.http.get(`${this.API}/expensecodes`, { headers: { Authorization: `Bearer ${this.authService.getToken()}` } }).pipe(
      map((data: any) => {
        // console.log(data);  
        return data;
      })
    );
    return request;
  }

  getManageSummaryByCaseId(caseId: string) {
    let request = this.http.get(`${this.API}/case/${caseId}/manage/summary`, { headers: { Authorization: `Bearer ${this.authService.getToken()}` } }).pipe(
      map((data: any) => {
        // sort by position
        var sortByProperty = function (property) {

          return function (x, y) {
      
              return ((x[property] === y[property]) ? 0 : ((x[property] > y[property]) ? 1 : -1));
      
          };
      
        };
        data.sort(sortByProperty('position'));
        console.log(data);
        return data;
      })
    );
    return request;
  }
}