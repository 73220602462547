import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ValidatorService } from 'angular4-material-table';

@Injectable({
  providedIn: 'root'
})
export class TimekeeperValidatorService {
  getRowValidator(): FormGroup {
    return new FormGroup({
      'role': new FormControl(null, Validators.required),
      'name': new FormControl(null,Validators.required),
      'lawfirms': new FormControl(null),
      'timekeeper_id': new FormControl(null,Validators.required),
      'justification': new FormControl(null),
      'rate': new FormControl(null,Validators.required),
      'status': new FormControl(null,Validators.required),
      'comments': new FormControl(null),
      });
  }
  constructor() { }
}