import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../authentication.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(public auth: AuthenticationService) {}

  ngOnInit() {
  }

  isLoggedIn: boolean = false;

  ngDoCheck() {
    this.isLoggedIn = this.auth.isLoggedIn();
  }

}
