export class PlanItem {
  _id: string;
  task_code_id: string;
  description: string;
  timekeeper_id: string;
  hours: number;
  fee: number;
  expense_code_id: string;
  amount: number;
  comments: string;
}