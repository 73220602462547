import { Injectable, } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, ParamMap} from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { HttpClient } from '@angular/common/http';
import { environment } from './../environments/environment';
import { map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate{

  API = environment.apiUrl;

  constructor(private http: HttpClient, private authService: AuthenticationService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean|Observable<boolean> {
  	let url: string = state.url;
    let paramMap: ParamMap = route.paramMap;
  	return this.checkLogin(url, paramMap);
  }

  checkLogin(url: string, paramMap: ParamMap): boolean|Observable<boolean> {
    return this.authService.isLoggedIn();
    // if (this.authService.isLoggedIn()) {
    // 	// ensure that the role allows the user to acces this route.
    // 	let role: string = this.authService.getUserDetails().role;
    // 	if(url == "/cases"){
    // 		if(role == "matter_manager" || role == "outside_counsel" || role == "org_admin"){
    // 			return true;
    // 		} else {
    // 			this.router.navigate(['/']);
    // 			return false;
    // 		}
    // 	} else if(url.startsWith("/case") && paramMap.has("id")){
    //     // this is the route /case/<case_id>
    //     let id = paramMap.get("id");
    //     // find if this user has permission to access this case from back end API
    //     // GET /case/id:?canAccess
    //     return this.http.get<boolean>(`${this.API}/case/${id}?method=isallowed`, { headers: { Authorization: `Bearer ${this.authService.getToken()}` }}).pipe(
    //           map((data: any) => {
    //             return data.result;                
    //           }));
    //   }
    // } else {
    //   // Store the attempted URL for redirecting
    //   this.authService.redirectUrl = url;

    //   // Navigate to the login page with extras
    //   this.router.navigate(['/login']);
    //   return false;
    // }
  }
}
