import { Component, OnInit } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from './authentication.service';

// Import rxjs map operator
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  isCollapsed = true;
  isLoggedIn: boolean = false;
  currentUser = {
    name: '',
    role: ''
  }
  // Declare empty list of people
  people: any[] = [];

  constructor(public auth: AuthenticationService) {}

  // Angular 2 Life Cycle event when component has been initialized
  ngOnInit() {
  }

  ngDoCheck() {
    this.isLoggedIn = this.auth.isLoggedIn();
    if(this.isLoggedIn){
      this.currentUser.name = this.auth.getUserDetails().name;
      this.currentUser.role = this.auth.getUserDetails().role;
    }
  }
}