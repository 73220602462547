import {Component, OnInit, ViewChild} from '@angular/core';
import { DataService } from '../data.service';
import { MatSort, MatTableDataSource } from '@angular/material';
import { AuthenticationService } from '../authentication.service';
import { Router } from '@angular/router';

export interface VideoElement {
  title: string;
  url: string;
  createdAt: string;
  tags: string[]
}

@Component({
  selector: 'app-cases',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.css']
})
export class VideosComponent implements OnInit {

  videos: Array<VideoElement>;
  dataSource: MatTableDataSource<VideoElement>;
  displayedColumns: string[] = ['url','title', 'createdAt','tags'];
  
  currentUser = {
    name: ''
  }

  @ViewChild(MatSort) sort: MatSort;

  constructor(private dataService: DataService, private auth: AuthenticationService, private router: Router) { }

  ngOnInit() {
  	this.dataService.getVideos()
      .subscribe(res => {
      		this.videos = res;
      		this.dataSource = new MatTableDataSource(this.videos);
      		this.dataSource.sort = this.sort;
      		console.log(this.dataSource);
      	});
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngDoCheck() {
    if(this.auth.isLoggedIn()){
      this.currentUser.name = this.auth.getUserDetails().name;
    }
  }

  goToCase(row){
    this.router.navigate(['/case/'+row._id,{ outlets: { case_router_outlet: 'manage' } }]);
  }

  goToVideoCreate(){
    this.router.navigate(['/video/create']);
  }

}