import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ViewChild
} from "@angular/core";
import {
  TableDataSource,
  TableElement,
  ValidatorService
} from "angular4-material-table";
import { PlanPhaseItem } from "./plan-phase-item";
import { Case } from "./case";
import { DataService } from "../../data.service";
import { PlanPhaseItemValidatorService } from "./plan-phase-item-validator.service";
import { ActivatedRoute, Router } from "@angular/router";
import { FormControl, Validators } from "@angular/forms";
import { MatTabGroup } from "@angular/material";
import { ReplaySubject, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";

export interface Area {
  value: string;
  viewValue: string;
}

export interface Firm {
  name: string;
  _id: string;
}

export interface Manager {
  value: string;
  viewValue: string;
}

export interface Counsel {
  value: string;
  viewValue: string;
}

@Component({
  selector: "app-manage",
  providers: [
    { provide: ValidatorService, useClass: PlanPhaseItemValidatorService }
  ],
  templateUrl: "./manage.component.html",
  styleUrls: ["./manage.component.css"]
})
export class ManageComponent implements OnInit {
  displayColumns = [];
  inputColumns = ["planPhase", "budget", "work"];
  showTable: boolean;

  resultAvailable: boolean = false;

  areas: Area[] = [
    { value: "Administration", viewValue: "Administration" },
    { value: "Antitrust", viewValue: "Antitrust" },
    { value: "Asbestos Claims", viewValue: "Asbestos Claims" },
    { value: "Aviation", viewValue: "Aviation" },
    { value: "Bankruptcy-Workouts", viewValue: "Bankruptcy-Workouts" },
    {
      value: "Corporate-Subsidiary-Structure",
      viewValue: "Corporate-Subsidiary-Structure"
    },
    { value: "Divestitures", viewValue: "Divestitures" },
    { value: "Environmental", viewValue: "Environmental" },
    { value: "Insurance", viewValue: "Insurance" },
    { value: "Intellectual Property", viewValue: "Intellectual Property" },
    { value: "Investigations", viewValue: "Investigations" },
    {
      value: "Joint Development Agreement",
      viewValue: "Joint Development Agreement"
    },
    { value: "Joint Ventures", viewValue: "Joint Ventures" },
    { value: "Labor and Employment", viewValue: "Labor and Employment" },
    { value: "Lobbying", viewValue: "Lobbying" },
    { value: "Pension and Benefits", viewValue: "Pension and Benefits" },
    { value: "Permits and Licenses", viewValue: "Permits and Licenses" },
    {
      value: "Product Liability & Casualty",
      viewValue: "Product Liability & Casualty"
    },
    { value: "Real Estate", viewValue: "Real Estate" },
    { value: "Sales", viewValue: "Sales" },
    { value: "Securities-Finance", viewValue: "Securities-Finance" },
    { value: "Tax", viewValue: "Tax" },
    { value: "Trade Compliance", viewValue: "Trade Compliance" },
    { value: "Treasury", viewValue: "Treasury" }
  ];

  managers: Manager[] = [{ value: "Alice", viewValue: "Alice" }];

  counsels: Counsel[] = [{ value: "Bob", viewValue: "Bob" }];

  lawFirms: Array<Firm>;
  businessUnits: Array<string>;
  approaches: Array<string>;
  endgames: Array<string>;

  roleFilterCtrl: FormControl = new FormControl();
  firmFilterCtrl: FormControl = new FormControl();

  public filteredRoles: ReplaySubject<Area[]> = new ReplaySubject<Area[]>(1);
  public filteredFirms: ReplaySubject<Firm[]> = new ReplaySubject<Firm[]>(1);

  @Input()
  planPhaseItemList = [];
  displayData: any[];

  @ViewChild(MatTabGroup)
  tabGroup: MatTabGroup;

  dataSource: TableDataSource<PlanPhaseItem>;

  constructor(
    private planItemValidator: ValidatorService,
    private dataService: DataService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    let caseId = this.activatedRoute.parent.snapshot.params["id"];

    // get law firms
    this.dataService.getLawFirms().subscribe(res => {
      this.lawFirms = res;
      this.filteredFirms.next(this.lawFirms.slice());
      // console.log(res);
    });

    // get business units
    this.dataService.getBusinessUnits().subscribe(res => {
      this.businessUnits = res;
    });

    // get approaches
    this.dataService.getApproaches().subscribe(res => {
      this.approaches = res;
    });

    // get endgames
    this.dataService.getEndGames().subscribe(res => {
      this.endgames = res;
    });

    this.roleFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterRoles();
      });

    this.firmFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterFirms();
      });

    this.filteredRoles.next(this.areas.slice());

    this.dataService.getManageSummaryByCaseId(caseId).subscribe(res => {
      setTimeout(() => {
        this.resultAvailable = true;
        this.planPhaseItemList.push({"planphase":".", "budget": "PLAN","work":"WORK"});
        this.planPhaseItemList.push({"planphase":"TOTAL","budget": res.reduce((prev, cur) => prev + cur.budget, 0),"work":res.reduce((prev, cur) => prev + cur.work, 0)});        
        this.planPhaseItemList.push(...res);
        
        this.displayColumns = this.planPhaseItemList.map(x => x.planphase.toString());
        this.displayData = this.inputColumns.map(x => this.formatInputRow(x));
        // get rid of the first row of data
        this.displayData.shift();
        
        console.log(this.displayColumns);
        console.log(this.displayData);        
        this.dataSource = new TableDataSource<any>(
          this.displayData,
          PlanPhaseItem,
          this.planItemValidator
        );
        this.showTable = true;
        this.tabGroup.selectedIndex = 1;
      }, 1000);
    });
  }

  formatInputRow(row) {
    const output = {};

    for (let i = 0; i < this.planPhaseItemList.length; ++i) {
      output[this.planPhaseItemList[i].planphase] = this.planPhaseItemList[i][row];
    }

    return output;
  }

  private filterRoles() {
    if (!this.areas) {
      return;
    }
    // get the search keyword
    let search = this.roleFilterCtrl.value;
    if (!search) {
      this.filteredRoles.next(this.areas.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    console.log(search);
    // filter the roles
    this.filteredRoles.next(
      this.areas.filter(
        role => role.viewValue.toLowerCase().indexOf(search) > -1
      )
    );
  }

  private filterFirms() {
    if (!this.lawFirms) {
      return;
    }
    // get the search keyword
    let search = this.firmFilterCtrl.value;
    if (!search) {
      this.filteredFirms.next(this.lawFirms.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    console.log(search);
    // filter the roles
    this.filteredFirms.next(
      this.lawFirms.filter(firm => firm.name.toLowerCase().indexOf(search) > -1)
    );
  }

  private _onDestroy = new Subject<void>();
}
