import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { AuthenticationService } from '../../authentication.service';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { ViewChild } from '@angular/core';
import { AngularFileUploaderComponent } from "angular-file-uploader";

@Component({
	selector: 'app-work',
	templateUrl: './work.component.html',
	styleUrls: ['./work.component.css']
})
export class WorkComponent implements OnInit {
	
	@ViewChild('fileUpload1')
    private fileUpload1:  AngularFileUploaderComponent;

	constructor(private route: ActivatedRoute, private dataService: DataService, private authService: AuthenticationService, private snackBar: MatSnackBar) {
	}

	ngOnInit() {
		this.caseId = this.route.parent.snapshot.params["id"];
		this.afuConfig = {
			multiple: false,
			formatsAllowed: ".csv",
			maxSize: "20",
			uploadAPI:  {
				url: `${this.dataService.API}/case/${this.caseId}/work/upload`,
				headers: {
					"Authorization" : `Bearer ${this.authService.getToken()}`
				}
			},
			theme: "dragNDrop",
			hideProgressBar: false,
			hideResetBtn: true,
			hideSelectBtn: false
		};
	}

	caseId = "";

	afuConfig = {};

	DocUpload(apiResponseEvent){
		var jsonRes = JSON.parse(apiResponseEvent.responseText);
		this.fileUpload1.resetFileUpload();
		if(jsonRes.result == false){		
			this.snackBar.open(jsonRes["messages"].toString(), 'Undo', {});			
		} else {

		}
		
	}
}
